.uk-link {
	display: inline-flex;
	align-items: center;
	transition: var(--global-transition);
}

.uk-link--plain {
	text-decoration: none;

	&:hover {
		text-decoration: underline;
	}
}

.text--56 {
  font-size: clamp(2.45rem, 3vw, 3.5rem);
  /* 3.5rem - 30% */
}

.text--52 {
  font-size: clamp(2.275rem, 3vw, 3.25rem);
  /* 3.25rem - 30% */
}

.text--48 {
  font-size: clamp(2.1rem, 3vw, 3rem);
  /* 3rem - 30% */
}

.text--44 {
  font-size: clamp(1.925rem, 3vw, 2.75rem);
  /* 2.75rem - 30% */
}

.text--40 {
  font-size: clamp(1.75rem, 3vw, 2.5rem);
  /* 2.5rem - 30% */
}

.text--36 {
  font-size: clamp(1.575rem, 3vw, 2.25rem);
  /* 2.25rem - 30% */
}

.text--32 {
  font-size: clamp(1.4rem, 3vw, 2rem);
  /* 2rem - 30% */
}

.text--28 {
  font-size: clamp(1.225rem, 3vw, 1.75rem);
  /* 1.75rem - 30% */
}

.text--24 {
  font-size: clamp(1.05rem, 3vw, 1.5rem);
  /* 1.5rem - 30% */
}

.text--20 {
  font-size: clamp(0.875rem, 3vw, 1.25rem);
  /* 1.25rem - 30% */
}

.text--16 {
  font-size: clamp(0.7rem, 3vw, 1rem);
  /* 1rem - 30% */
}

.text--12 {
  font-size: clamp(0.525rem, 3vw, 0.75rem);
  /* 0.75rem - 30% */
}

.uk-text--inline {
  display: inline-flex;
  align-items: center;
}

.number {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  font-weight: var(--font-bold);
  border: 0.0625rem solid $global-primary-background;
}
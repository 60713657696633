.uk-button {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: var(--font-bold);
  font-size: 1.125rem;
  border: 0.0625rem solid transparent;
  transition: var(--global-transition);
}

.uk-button--hollow {
  background-color: transparent;

  &.uk-button-secondary {
    color: $global-secondary-background;
    border-color: $global-secondary-background;

    &:hover {
      color: $global-inverse-color;
    }
  }
}
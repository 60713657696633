.uk-list {
  position: relative;

  li {
    position: relative;
  }
}

.uk-list--lang {
  display: inline-flex;
  align-items: center;
  gap: $global-margin;

  li + li {
    &::before {
      content: '/';
      position: absolute;
      top: 0;
      left: -0.625rem;
    }
  }
}
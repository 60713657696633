.uk-input,
.uk-select,
.uk-textarea {
  padding-inline: calc($global-margin * 1.5);
  border: 0.0938rem solid var(--gray-200-color);
  font-weight: var(--font-bold);
  transition: var(--global-transition);

  &:hover,
  &:focus {
    border-color: $global-primary-background;
  }
}

.uk-form-label {
  margin-bottom: 0.5rem;
  cursor: pointer;
  font-size: $form-label-font-size;
  font-weight: var(--font-bold);
  color: $form-label-color;
}
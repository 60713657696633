.form {
  position: relative;
}

.form__group {
  position: relative;
  display: flex;
  flex-direction: column;

  &.is-invalid {
    .uk-form-label {
      color: $global-danger-background;
    }

    .uk-input,
    .uk-textarea {
      color: $global-danger-background;
      border-color: $global-danger-background;
    }
  }
}

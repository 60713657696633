.uk-picture--partner {
  position: relative;
  display: flex;

  img {
    max-height: 2.25rem;
    transition: var(--global-transition);
  }

  img:last-child {
    top: 0;
    left: 0;
    position: absolute;
    opacity: 0;
  }

  &:hover {
    img:first-child {
      opacity: 0;
    }

    img:last-child {
      opacity: 1;
    }
  }
}
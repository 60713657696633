.uk-icon:not(.uk-offcanvas-close):not(.uk-lightbox-button) {
  display: inline-block;
  flex: 0 0 auto;
}

.uk-icon:not(.uk-preserve) [stroke*="#"]:not(.uk-preserve) {
  stroke: currentcolor;
}

.uk-grid--vertical {
  gap: $grid-gutter-vertical 0;
}

.uk-grid--vertical-medium {
  gap: calc($grid-gutter-vertical * 2) 0;
}